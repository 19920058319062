import React from 'react'

import { MenuItem } from '../MenuItem'

import { useGlobalState } from '../../../../context/state'
import { useRenderDT } from '../../../../context/render-tree'

export const FieldToSortByMenu = () => {
  const {
    sortableAMVFields,
    setAMVSortField,
    AMVFieldToSortBy
  } = useGlobalState()
  const {
    toggleRender
  } = useRenderDT()

  return (
    <div>
      {
        sortableAMVFields.map(field =>
          <MenuItem
            selected={field.value === AMVFieldToSortBy}
            key={field.value}
            onClick={() => { setAMVSortField(field.value); toggleRender('sortableAMVFieldsIsVisible') }}
            primaryText={field.text}
            multiSelect
          />
        )
      }
    </div>
  )
}
