import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { MenuItem } from '../MenuItem'

import { useGlobalState } from '../../../../context/state'
import { useRenderDT } from '../../../../context/render-tree'

export const FieldToFilterByMenu = () => {
  const { filterableAMVFields, AMVFieldToFilterBy } = useGlobalState()
  const { toggleRender } = useRenderDT()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div>
      {
        filterableAMVFields.map(field =>
          <MenuItem
            selected={field.value === AMVFieldToFilterBy}
            key={field.value}
            onClick={() => { setSearchParams({ ...Object.fromEntries(searchParams), fieldName: field.value }); toggleRender('AMVFieldValuesToFilterByIsVisible', true) }}
            primaryText={field.text || '-'}
            multiSelect
          />
        )
      }
    </div>
  )
}
