import React from 'react'
import Divider from '@mui/material/Divider'
import cn from 'classnames'

import { BootstrapTooltip } from '../../../MainAreaHeader'

import './styles.scss'

export const MenuItem = ({
  secondaryText,
  primaryText,
  onClick,
  selected
}) => {
  return (
    <div>
      <div
        className={cn('filter', { selected })}
        onClick={onClick}
        style={{ padding: !secondaryText && '15px 10px' }}
      >
        <div className="background" />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexFlow: 'row nowrap' }}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {primaryText || '-'}
          </span>
        </div>
        {secondaryText && (
          <div className="secondary-text">
            {secondaryText.map(({ text, msg }) => {
              return (
                <BootstrapTooltip key={msg} title={msg} position="top">
                  <span className="info">{text}</span>
                </BootstrapTooltip>
              )
            })}
          </div>
        )}
      </div>
      <Divider />
    </div>
  )
}
