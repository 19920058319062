import React, { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import { useLocalStorage } from '@rehooks/local-storage'
import _ from 'lodash'
import { Helmet } from 'react-helmet'

import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import { green } from '@mui/material/colors'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

import './styles.scss'
import { useAPI } from '../../context/useAPI'

const Item = ({ animeTitle, song, artist, description, url: foundUrl, accepted, submit, mine, accept, reject }) => {
  const [url, setUrl] = useState('')

  return (
    <li className='item'>
      <div className='item-text'><b>Anime Title:</b> {animeTitle || 'Don\'t remember'}</div>
      <div className='item-text'><b>Song:</b> {song || 'Don\'t remember'}</div>
      <div className='item-text'><b>Artist:</b> {artist || 'Don\'t remember'}</div>
      <div className='item-text'><b>Description:</b> {description || '-'}</div>
      {foundUrl
        ? (
            <div style={{ display: 'flex', gap: 32, alignItems: 'end', marginTop: 36 }}>
              <TextField
                id="outlined-read-only-input"
                size='small'
                variant="outlined"
                label="Read Only"
                defaultValue={foundUrl}
                InputProps={{
                  readOnly: true
                }}
              />
              {
                accepted === undefined
                  ? mine
                    ? (
                        <>
                          <Button sx={{ height: 40 }} component="label" variant="contained" color="success" onClick={accept}>
                            This is it!
                          </Button>
                          <Button sx={{ height: 40 }} component="label" variant="contained" color="error" onClick={reject}>
                            This is not it!
                          </Button>
                        </>
                      )
                    : null
                  : accepted === true
                    ? <CheckCircleIcon sx={{ fill: green[500], height: 40, width: 40 }} />
                    : <CancelIcon sx={{ fill: green[500], height: 40, width: 40 }} />
              }
            </div>
          )
        : null}
      {(!mine && !foundUrl) && (
        <div style={{ display: 'flex', gap: 32, alignItems: 'end' }}>
          <TextField
            style={{ marginTop: 20 }}
            size='small'
            label="URL to AMV"
            variant="outlined"
            onChange={e => setUrl(e.target.value)}
            value={url}
          />
          <Button sx={{ height: 40 }} component="label" variant="contained" onClick={() => submit(url)}>
            submit
          </Button>
        </div>
      )}
    </li>
  )
}

const title = 'AMV is Art | Help find AMVs'
const description = 'This is a page where you can help others find AMVs they are looking for. And also see your requests and whether someone found your AMV.'

const someStructuredData = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: title,
  url: 'https://amv.art/'
}

export const HelpFind = ({ missingReports, jwtMock }) => {
  const init = (!process.env.SERVER && window.missingReports) || missingReports

  const [reports, setReports] = useState(init)
  const [mine, setMine] = useState(false)

  const [jwtClient] = useLocalStorage('jwt')

  const api = useAPI()

  const jwt = process.env.SERVER ? jwtMock : jwtClient

  const user = jwt && jwtDecode(jwt)

  useEffect(() => {
    api.getReports().then(({ missingReports }) => setReports(missingReports))
  }, [])

  const submit = id => url => api.submit({ id, url }).then(updateReport)

  const accept = id => api.accept({ id }).then(updateReport)

  const reject = id => api.reject({ id }).then(updateReport)

  const updateReport = (newReport) => {
    const reportToReplaceIndex = reports.findIndex(i => i._id === newReport._id)
    const newReports = [...reports.slice(0, reportToReplaceIndex), newReport, ...reports.slice(reportToReplaceIndex + 1)]
    setReports(newReports)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', overflow: 'auto', paddingBottom: 30, height: 'calc(100dvh - 88px)' }}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSON.stringify(someStructuredData)}</script>
      </Helmet>
      {jwt ? <div style={{ width: '90%', marginBottom: '20px' }}>Mine: <Switch value={mine} onChange={() => setMine(!mine)} /></div> : null}
      <ul
        aria-label="contacts"
        className='list'
      >
        {_.orderBy(reports, 'createdAt', 'desc').filter(i => mine ? i.email === user.email : true).map((report, index, reports) => (
          <Item
            key={report._id}
            {...report}
            submit={submit(report._id)}
            isLastItem={index === reports.length - 1}
            mine={user?.email === report.email}
            accept={() => accept(report._id)}
            reject={() => reject(report._id)}
          />
        ))}
      </ul>
    </div>
  )
}
