import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import './styles.scss'
import { useAPI } from '../../context/useAPI'
import { useGlobalState } from '../../context/state'

const title = 'AMV is Art | Report A Loss'
const pageDescription = 'Report a loss. This is a page where you can fill out a form describing AMV that got deleted from youtube and you would really like to find it.'

const someStructuredData = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: title,
  url: 'https://amv.art/'
}

export const ReportLoss = () => {
  const [animeTitle, setAnimeTitle] = useState('')
  const [song, setSong] = useState('')
  const [artist, setArtist] = useState('')
  const [description, setDescription] = useState('')

  const { setShowSnackbar } = useGlobalState()

  const api = useAPI()

  const report = async () => {
    await api.report({
      animeTitle,
      song,
      artist,
      description
    })

    setShowSnackbar({ msg: 'Successfully created report' })

    setAnimeTitle('')
    setSong('')
    setArtist('')
    setDescription('')
  }

  return (
    <div className='upload-container'>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={pageDescription} />
        <script type="application/ld+json">{JSON.stringify(someStructuredData)}</script>
      </Helmet>
      <div className='upload-file-fields'>
        <TextField size='small' label="Anime Title" variant="outlined" onChange={e => setAnimeTitle(e.target.value)} value={animeTitle} />
        <TextField size='small' label="Song" variant="outlined" onChange={e => setSong(e.target.value)} value={song} />
        <TextField size='small' label="Artist" variant="outlined" onChange={e => setArtist(e.target.value)} value={artist} />
        <TextField
          value={description}
          onChange={e => setDescription(e.target.value)}
          label="Additional description"
          multiline
          rows={4}
        />
      </div>
      <Button onClick={report} variant="outlined">Report</Button>
    </div>
  )
}
