import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { FieldToFilterByMenu } from './components/FieldToFilterByMenu'
import { FieldToSortByMenu } from './components/FieldToSortByMenu'
import { FieldValueToFilterByMenu } from './components/FieldValueToFilterByMenu'
import { AMVinfoView } from './components/AMVInfoView'
import { AMVinfoEditView } from './components/AMVInfoEditView'

import { useRenderDT } from '../../context/render-tree'

import './styles.scss'

export const Drawer = ({ className }) => {
  const loca = useLocation()
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const {
    renderDT: {
      AMVInfoIsVisible,
      sortableAMVFieldsIsVisible,
      filterableAMVFieldsIsVisible,
      AMVFieldValuesToFilterByIsVisible,
      AMVInfoEditIsVisible
    },
    drawerIsOpen
  } = useRenderDT()

  useEffect(() => {
    const arr = loca.pathname.split('/').filter(Boolean)
    const id = arr.length > 1 && arr[arr.length - 1]
    if (!isMobile || (loca.pathname.includes('library') && !id)) {
      return
    }

    const vid = document.querySelector('video')
    const drawerContainer = document.querySelector('.main-area-drawer')
    if (!drawerContainer) {
      return
    }
    vid.addEventListener('loadeddata', () => {
      const vid = document.querySelector('video')
      drawerContainer.style.height = `calc(100dvh - 144px - ${vid.offsetHeight}px)`
    })

    drawerContainer.style.height = `calc(100dvh - 189px - ${vid.offsetHeight}px)`
  }, [loca.pathname, isMobile, AMVInfoIsVisible.value])

  return (
    <div
      className={
        classNames('drawer', className, {
          opened: drawerIsOpen,
          closed: !drawerIsOpen
        })
      }
    >
      {filterableAMVFieldsIsVisible.value ? <FieldToFilterByMenu /> : null}
      {AMVFieldValuesToFilterByIsVisible.value ? <FieldValueToFilterByMenu /> : null}
      {sortableAMVFieldsIsVisible.value ? <FieldToSortByMenu /> : null}
      {AMVInfoIsVisible.value ? <AMVinfoView /> : null}
      {AMVInfoEditIsVisible.value ? <AMVinfoEditView /> : null}
    </div>
  )
}
