/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import classNames from 'classnames'
import format from 'format-duration'
import { Link } from 'react-router-dom'

import Clock from '@mui/icons-material/Schedule'
import Views from '@mui/icons-material/Visibility'

import { formatDistanceToNow } from 'date-fns'

import { Hint } from '../Hint'
import { BootstrapTooltip } from '../MainAreaHeader'

import { useGlobalState } from '../../context/state'

import './styles.scss'
import { useRenderDT } from '../../context/render-tree'

export const AMVItem = forwardRef(({
  id,
  originalName,
  animeTitle,
  artist,
  song,
  imgUrl,
  additionDate,
  youtubeUploadDate,
  youtubeViews,
  duration
}, ref) => {
  const { selectAMV } = useGlobalState()
  const { toggleRender } = useRenderDT()

  return (
    <Link
      className={classNames({ 'amv-list-item': false, 'amv-item': true })}
      ref={ref}
      to={`/library/${id}`}
    >
      <div
        className="amv-preview"
        onClick={() => { selectAMV(id); toggleRender('AMVIsVisible', true) }}
      >
        <p className="amv-title">{originalName}</p>
        <img
          className={classNames('amv-img')}
          src={imgUrl}
          alt={`AMV preview for ${originalName}`}
        />
      </div>
      <div style={{ width: '100%' }}>
        <div className="amv-info">
          <Hint className="amv-info-text" msg={animeTitle} />
          <Hint className="amv-info-text" msg={`${artist} - ${song}`} />
        </div>
        <div className="amv-dates">
          <BootstrapTooltip title="addition date" position="top">
            <span className="amv-date-item">
              {formatDistanceToNow(new Date(additionDate))} ago
            </span>
          </BootstrapTooltip>
          <BootstrapTooltip title="creation date" position="top">
            <span className="amv-date-item">
              {formatDistanceToNow(new Date(youtubeUploadDate))} ago
            </span>
          </BootstrapTooltip>
        </div>
        <div className="amv-misc">
          <BootstrapTooltip title="youtube views" position="top">
            <div className="amv-misc-item-group">
              <Views className="amv-misc-icon" />
              <span>{Number(youtubeViews).toLocaleString()}</span>
            </div>
          </BootstrapTooltip>
          <div className="amv-misc-item-group">
            <Clock className="amv-misc-icon" />
            <span>{format(Math.round(duration))}</span>
          </div>
        </div>
      </div>
    </Link>
  )
})
