import React from 'react'
import { Helmet } from 'react-helmet'
import { formatISODuration } from 'date-fns'

import { useGlobalState } from '../../context/state'

import './styles.scss'
import classNames from 'classnames'
import { useRenderDT } from '../../context/render-tree'

export const AMVView = () => {
  const globalState = useGlobalState()
  const { renderDT: { AMVInfoIsVisible } } = useRenderDT()

  const { selectedAMV: { duration, animeTitle, originalName, song, artist, youtubeViews, imgUrl, youtubeUploadDate, creator } } = globalState

  const desc = `This is AMV that someone really wanted to preserve. It's for anime ${animeTitle}. The song is ${song} by ${artist}. It's original name is ${originalName}`
  const someStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    description: desc,
    duration: formatISODuration({ seconds: parseInt(duration) }),
    embedUrl: `${globalState.selectedAMV.url}#t=0.1`,
    interactionCount: youtubeViews,
    name: originalName,
    thumbnailUrl: [
      imgUrl
    ],
    uploadDate: youtubeUploadDate,
    genre: 'Art',
    author: creator
  }

  return (
    <div className='amv-view-container'>
      <Helmet encodeSpecialCharacters={false}>
        <title>{originalName}</title>
        <meta name="description" content={desc} />
        <script type="application/ld+json">{JSON.stringify(someStructuredData)}</script>
      </Helmet>
      <video className={classNames('video-view', { 'video-center': !AMVInfoIsVisible.value })} controls preload="metadata">
        <source src={`${globalState.selectedAMV.url}#t=0.1`} type="video/mp4" />
      </video>
    </div>
  )
}
