import React from 'react'
import FlipMove from 'react-flip-move'
import { jwtDecode } from 'jwt-decode'
import { useLocalStorage } from '@rehooks/local-storage'
import { Helmet } from 'react-helmet'
import { useParams, useSearchParams } from 'react-router-dom'

import { AMVView } from '../AMVView'
import { MainAreaHeader } from '../MainAreaHeader'
import { AMVItem } from '../AMVItem'
import { Drawer } from '../Drawer'

import { useGlobalState } from '../../context/state'
import { useRenderDT } from '../../context/render-tree'

import './styles.scss'

let title = 'AMV is Art | AMV Library'
let description = 'AMV Library is where you have a list of all AMVs on this site. You can filter it, sort it, and obviously watch it.'

const someStructuredData = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: title,
  url: 'https://amv.art/'
}

export const MainArea = () => {
  const { originalName: id } = useParams()
  const [searchParams] = useSearchParams()
  const searchParamsObj = Object.fromEntries(searchParams)

  const structuredDataForAnimePage = { ...someStructuredData }

  if (searchParamsObj.fieldName === 'animeTitle' && searchParamsObj.fieldValue) {
    structuredDataForAnimePage.name += `for ${searchParamsObj.fieldValue}} AMVs`
    title = `AMV is Art | ${searchParamsObj.fieldValue} AMV`
    description = `${searchParamsObj.fieldValue} AMVs, ${searchParamsObj.fieldValue} AMV, AMV of ${searchParamsObj.fieldValue}, watch`
  }

  const {
    AMVsFilteredAndSorted,
    showOnlyMyAMVs
  } = useGlobalState()

  const {
    renderDT: {
      AMVIsVisible,
      gridIsVisible
    },
    drawerIsOpen
  } = useRenderDT()

  const [jwt] = useLocalStorage('jwt')

  const user = jwt && jwtDecode(jwt)

  const rootPage = !id
  return (
    <div className="main-area">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        {rootPage && <script type="application/ld+json">{JSON.stringify(someStructuredData)}</script>}
      </Helmet>
      <MainAreaHeader />
      {AMVIsVisible.value ? <AMVView /> : null}
      {drawerIsOpen ? <Drawer className='main-area-drawer' /> : null}
      {
        gridIsVisible.value
          ? (
              <FlipMove className="amv-grid">
                {AMVsFilteredAndSorted.filter(i => showOnlyMyAMVs ? i.email === user.email : true).map(AMV => <AMVItem key={AMV.id} {...AMV} />)}
              </FlipMove>
            )
          : null
      }
    </div>
  )
}
