import { useLocalStorage } from '@rehooks/local-storage'
import { useGlobalState } from './state'

export const useAPI = () => {
  const [jwt] = useLocalStorage('jwt')
  const { setShowSnackbar } = useGlobalState()

  const fwj = async ({ url, body, method = 'GET', withJ = true }) => {
    if (withJ && !jwt) {
      return
    }

    try {
      const result = await fetch(`${process.env.API_URL}${url}`, {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`
        },
        body: body && JSON.stringify(body)
      })

      return result.json()
    } catch (err) {
      setShowSnackbar({ error: true, msg: 'There is an error with api' })
    }
  }

  const report = (body) => fwj({ url: '/report', body, method: 'POST' })

  const accept = body => fwj({ url: '/accept-found-report', body, method: 'PATCH' })

  const reject = body => fwj({ url: '/reject-found-report', body, method: 'PATCH' })

  const submit = body => fwj({ url: '/amv-found-report', body, method: 'POST' })

  const getReports = body => fwj({ url: '/report', body, withJ: false })

  const updateAMV = (id, body) => fwj({ url: `/amv/${id}`, body, method: 'PATCH' })

  return { report, accept, reject, submit, getReports, updateAMV }
}
