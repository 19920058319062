import React from 'react'

import './styles.scss'
import { Link } from 'react-router-dom'

export const Logo = () => {
  return (
    <Link className='logo' to='/'>AMV.Art</Link>
  )
}
