import React from 'react'

import { MainArea } from '../MainArea'
import { Drawer } from '../Drawer'

import './styles.scss'

export const Container = () => {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <MainArea />
      <Drawer className='container-drawer' />
    </div>
  )
}
