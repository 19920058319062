import React, { useState } from 'react'

import Snackbar from '@mui/material/Snackbar'

const decorateWithStopPropogation = func => e => {
  e.stopPropagation()
  e.preventDefault()
  func(e)
}

export const Hint = ({ state, effects, msg, ...props }) => {
  const [open, setOpen] = useState(false)

  return (
  <div {...props} onClick={decorateWithStopPropogation(() => setOpen(true))}>
    {msg}
    <Snackbar
      open={open}
      message={msg}
      autoHideDuration={4000}
      onClose={() => setOpen(false)}
    />
  </div>
  )
}
