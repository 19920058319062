import React from 'react'

import { useGlobalState } from '../../../../context/state'

export const AMVinfoView = () => {
  const { selectedAMV } = useGlobalState()

  return (
    <div>
      <h2 style={{ marginTop: 0 }}>AMV Title</h2>
      <p className="dynamic-text">{selectedAMV.originalName}</p>
      <h2>Anime Title</h2>
      <p className="dynamic-text">{selectedAMV.animeTitle}</p>
      <h2>Artist</h2>
      <p className="dynamic-text">{selectedAMV.artist}</p>
      <h2>Song</h2>
      <p className="dynamic-text">{selectedAMV.song}</p>
    </div>
  )
}
