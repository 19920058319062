import React, { useState } from 'react'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import { useAPI } from '../../../../context/useAPI'
import { useGlobalState } from '../../../../context/state'
import { useRenderDT } from '../../../../context/render-tree'

import './styles.scss'

export const AMVinfoEditView = () => {
  const { selectedAMV, AMVs, setAMVs, selectAMV } = useGlobalState()
  const { toggleRender } = useRenderDT()

  const [newAnimeTitle, setNewAnimeTitle] = useState(selectedAMV.animeTitle)
  const [newSong, setNewSong] = useState(selectedAMV.song)
  const [newArtist, setNewArtist] = useState(selectedAMV.artist)

  const { updateAMV } = useAPI()

  const updateAMVWithFields = async () => {
    const newAMV = await updateAMV(selectedAMV._id, { animeTitle: newAnimeTitle, song: newSong, artist: newArtist })
    updateLocalAMV(newAMV)
    selectAMV(newAMV)
  }

  const updateLocalAMV = (newAMV) => {
    const AMVToReplaceIndex = AMVs.findIndex(i => i._id === newAMV._id)
    const newAMVs = [...AMVs.slice(0, AMVToReplaceIndex), newAMV, ...AMVs.slice(AMVToReplaceIndex + 1)]
    setAMVs(newAMVs)
    toggleRender('AMVInfoIsVisible', true)
    toggleRender('AMVInfoEditIsVisible', false)
  }

  return (
    <div className='edit-container'>
      <div className='edit-file-fields'>
        <TextField size='small' label="Anime Title" variant="outlined" onChange={e => setNewAnimeTitle(e.target.value)} value={newAnimeTitle} />
        <TextField size='small' label="Song" variant="outlined" onChange={e => setNewSong(e.target.value)} value={newSong} />
        <TextField size='small' label="Artist" variant="outlined" onChange={e => setNewArtist(e.target.value)} value={newArtist} />
      </div>
      <Button variant="outlined" onClick={updateAMVWithFields}>
        Update AMV
      </Button>
    </div>
  )
}
