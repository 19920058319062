import React, { memo } from 'react'
import { Helmet } from 'react-helmet'

import ChromeIcon from './assets/chrome.svg?url'
import YouTubeIcon from '@mui/icons-material/YouTube'

import './styles.scss'

// eslint-disable-next-line react/display-name
const VideoComponent = memo(() => {
  console.log('render video component')
  return (
    // eslint-disable-next-line react/no-unknown-property
    <video className='landing-video' autoPlay muted controls={false} loop playsInline>
      <source
        src='https://videos.amv.art/Uchiha_Obito_-_cruel_world_AMV_Full_HD.mp4'
        type="video/mp4"
      />
    </video>
  )
}, () => true)

const description = 'AMV is Art. And no one has a right to delete it. Here you can backup your favorite anime music video.'

const extensionLink = 'https://chromewebstore.google.com/detail/backup-amv/dgkemofeiaikkccckninlcnpojilphod?hl=en&authuser=0'

export const Landing = () => {
  const someStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'AMV is Art',
    url: 'https://amv.art/'
  }
  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>AMV is Art</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSON.stringify(someStructuredData)}</script>
      </Helmet>
      <VideoComponent />
      <div className='main-text'>
        <div className='center' style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <h1>AMV is Art</h1>
          <small>and no one has a right to delete it</small>
          <button onClick={() => location.href = '/upload'} className='button-upload-youtube'>Upload with just youtube link <YouTubeIcon /></button>
        </div>
      </div>
      <a className='extension' href={extensionLink} target="_blank" rel="noreferrer">
        <span>Get extension</span>
        <img src={ChromeIcon} alt="chrome extension icon" />
      </a>
    </>
  )
}
