import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import classNames from 'classnames'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

import Button from '@mui/material/IconButton'
import SortIcon from '@mui/icons-material/Sort'
import FilterIcon from '@mui/icons-material/FilterList'
import InfoIcon from '@mui/icons-material/Assignment'
import AMVIcon from '@mui/icons-material/Theaters'
import AMVGridIcon from '@mui/icons-material/ViewModule'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import EditIcon from '@mui/icons-material/Edit'

import { useGlobalState } from '../../context/state'
import { useRenderDT } from '../../context/render-tree'

import './styles.scss'

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} enterDelay={100} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#212121'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#212121'
  }
}))

const IconButton = ({ children, onClick, tooltip, disabled, hide = false }) => {
  return (
    <BootstrapTooltip
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14]
              }
            }
          ]
        }
      }}
      title={tooltip}
    >
      <Button className={classNames('icon-button', { 'hide-icon': hide })} disabled={disabled} disableRipple onClick={onClick}>
        {children}
      </Button>
    </BootstrapTooltip>
  )
}

export const MainAreaHeader = () => {
  const {
    selectedAMV,
    showOnlyMyAMVs,
    toggleMyAMVs,
    sortingDirection,
    toggleSortingDirection,
    jwtMock
  } = useGlobalState()
  const {
    toggleRender,
    renderDT
  } = useRenderDT()
  const {
    AMVFieldValuesToFilterByIsVisible,
    filterableAMVFieldsIsVisible,
    sortableAMVFieldsIsVisible,
    AMVInfoIsVisible,
    gridIsVisible,
    AMVIsVisible,
    AMVInfoEditIsVisible
  } = renderDT

  const navigate = useNavigate()

  const selectionColor = '#8DD4B2'
  const disabledColor = 'rgba(0, 0, 0, 0.26)'
  const [jwtClient] = useLocalStorage('jwt')
  const jwt = process.env.SERVER ? jwtMock : jwtClient

  return (
    <div className="main-area-header-container">
      <div>
        <IconButton disabled={AMVIsVisible.value || !jwt} onClick={toggleMyAMVs} tooltip="Show only your AMVs">
          <AccountCircleIcon sx={{ fill: (AMVIsVisible.value || !jwt) ? disabledColor : (showOnlyMyAMVs && selectionColor) }} />
        </IconButton>
        <IconButton disabled={!selectedAMV || AMVIsVisible.value} onClick={() => { toggleRender('AMVIsVisible'); navigate(`/library/${selectedAMV._id}`) }} tooltip="Show Selected AMV">
          <AMVIcon sx={{ fill: selectedAMV ? AMVIsVisible.value && selectionColor : disabledColor }} />
        </IconButton>
        <IconButton disabled={gridIsVisible.value} onClick={() => { toggleRender('gridIsVisible'); navigate('/library') }} tooltip="Show AMV Grid">
          <AMVGridIcon sx={{ fill: gridIsVisible.value && selectionColor }} />
        </IconButton>
        <IconButton disabled={!selectedAMV} onClick={() => toggleRender('AMVInfoIsVisible')} tooltip="Show AMV info">
          <InfoIcon sx={{ fill: selectedAMV ? AMVInfoIsVisible.value && selectionColor : disabledColor }} />
        </IconButton>
        <IconButton disabled={!selectedAMV} onClick={() => toggleRender('AMVInfoEditIsVisible')} tooltip="Edit AMV info">
          <EditIcon sx={{ fill: selectedAMV ? AMVInfoEditIsVisible.value && selectionColor : disabledColor }} />
        </IconButton>
      </div>
      <div>
        <IconButton disabled={AMVIsVisible.value} onClick={() => toggleRender('sortableAMVFieldsIsVisible')} tooltip="Choose field to Sort AMVs">
          <SortIcon sx={{ fill: AMVIsVisible.value ? disabledColor : (sortableAMVFieldsIsVisible.value && selectionColor) }} />
        </IconButton>
        <IconButton disabled={AMVIsVisible.value} onClick={toggleSortingDirection} tooltip="Asc/Desc">
          {
            sortingDirection === 'asc'
              ? <ArrowDownwardIcon sx={{ fill: AMVIsVisible.value && disabledColor }} />
              : <ArrowUpwardIcon sx={{ fill: AMVIsVisible.value && disabledColor }} />
          }
        </IconButton>
        <IconButton disabled={AMVIsVisible.value} onClick={() => toggleRender('filterableAMVFieldsIsVisible')} tooltip="Choose field to filter AMVs">
          <FilterIcon sx={{ fill: AMVIsVisible.value ? disabledColor : (filterableAMVFieldsIsVisible.value && selectionColor) }} />
        </IconButton>
        <IconButton disabled={AMVIsVisible.value} onClick={() => toggleRender('AMVFieldValuesToFilterByIsVisible')} tooltip="Choose values to filter AMVs">
          <FilterIcon sx={{ fill: AMVIsVisible.value ? disabledColor : (AMVFieldValuesToFilterByIsVisible.value && selectionColor) }} />
        </IconButton>
      </div>
    </div>
  )
}
